import { download } from "@/types/download";
import { Ref, ref } from "vue";

export function useGetDownloads(): [Ref<Array<download>>] {
  const downloads = ref<Array<download>>([]);
  Promise.resolve([
    { title: "[通知下载]关于调度 2019-2023年国家和省级文物保护项目资金情况的通知", href: "/Angular/Attachments/StaticFile/关于调度 2019-2023年国家和省级文物保护项目资金情况的通知.pdf" },
    // { title: "[系统功能]山东省省级重点文物保护专项资金项目绩效目标申报表", href: "#" },
    // { title: "[系统功能]2018年省级重点文物保护专项资金申报汇总表", href: "#" },
    // { title: "[系统功能]2018年山东省文物保护工程专项资金申请书文本格式", href: "#" },
    // { title: "[系统功能]2018年山东省省级文物保护单位保护项目计划书和年度计划表", href: "#" }
  ]).then((res) => {
    downloads.value = res;
  });
  return [downloads];
}
