import { notice } from "@/types/notice";
import { ref, Ref } from "vue";

export function useGetNotices(): [Ref<Array<notice>>] {
  const notices = ref<Array<notice>>([]);
  Promise.resolve([
    { title: "[其他]文物保护工程项目评审有关注意事项", content: "测试内容" },
    {
      title: "[申报通知]关于做好2020年度省级重点文物保护专项资金申报工作的通知",
      content: "测试内容",
    },
    {
      title:
        "[申报通知]关于做好2018年度省级文物保护专项补助资金项目计划书申报工作的通知",
      content: "测试内容",
    },
    {
      title:
        "[政策法规]关于开展国家及省级重点文物保护专项资金补助项目督查工作的方案",
      content: "测试内容",
    },
  ]).then((res) => {
    notices.value = res;
  });

  return [notices];
}
