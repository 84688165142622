import heritage from "@/types/heritage";
import request from "@/utils/request";
import { ref, Ref, reactive } from "vue";

export function useQueryProjects(filter) : Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  function query() {
    isLoading.value = true;
    request
      .get(`/project/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}
export function useQueryProjectOverviews(filter) : Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  function query() {
    isLoading.value = true;
    request
      .get(`/project/overview`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query];
}
